/*  ==========================================================================
    SIDEBAR
    ========================================================================== */

@mixin header--sidebar() {
  background-color: rgba($clr__ci--secondary, 0.85);
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(-100%);
  transition: transform .8s ease;
  z-index: $unit__zi--header-v3-sidebar;
  @media #{$mq__desktop--small} {
  background-size: auto 100%;
}
  a {
    color: $clr__typo--secondary;
    &:visited {
      color: rgba($clr__typo--secondary, 0.5);
    }
    &:hover {
      color: rgba($clr__typo--secondary, 0.5);
    }
  }
  .bn-grid__row.bn-grid__row--l.bn-grid__spacing--m.bn-grid__spacing-rm--right.bn-grid__spacing-rm--vertical.bn-sidebar__center.bn-main-menu__items {
    width: 50%;
    margin: 0;
  }
  .bn-sidebar__top,
  .bn-sidebar__center,
  .bn-sidebar__bottom {
    display: flex;
    width: 100%;
  }
  .bn-sidebar__top,
  .bn-sidebar__bottom {
    flex: none;
  }
  i.bn-icon {
    color: $clr__ci--primary;
  }
  i.bn-icon.bn-icon--close {
    color: $clr__white;
  }
  .bn-sidebar__top {
    padding-bottom: 20px;
    z-index: 101;
  }
  .bn-sidebar__center {
    @include scrollbar($clr__scrollbar,5px);
    flex: 1;
    overflow-y: auto;
  }
  .bn-sidebar__bottom {
    padding-top: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
  }
  // close
  .bn-close {
    color: $clr__typo--secondary;
    cursor: pointer;
    display: inline-block;
    pointer-events: initial;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    .bn-icon {
      font-size: 3.1em;
      line-height: 50px;
    }
    &:hover {
      color: rgba($clr__typo--secondary, 0.5);
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--aside {
      margin-top: 40px;
      img {
        @include img--rendering-crisp-edges();
        max-width: 100%;
      }
      .bn-main-logo__image {
        &.bn-main-logo__image--big {
          display: none;
        }
        &.bn-main-logo__image--big--novista {
          display: none;
        }
        &.bn-main-logo__image--small {
          display: inline-block;
          img {
            height: 60px;
          }
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    .bn-main-menu__lvl--0 {
      padding-left: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      > li > a {
        color: $clr__ci--primary !important;
        &:hover {
          color: #A07A7B !important;;
        }
      }
      .bn-main-menu__item {
        position: relative;
        width: 440px;
        &:hover ::after {
          transform: translateX(30px);
        }
        > .bn-main-menu__link {
          position: relative;
          z-index: 5;
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__typo--secondary;
          }
        }
      }
      > .bn-main-menu__item {
        > .bn-main-menu__link {
          display: block;
          padding: 15px 180px 15px 0;
          transition: color .2s ease-out;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 25px;
              text-align: left;
            }
            .bn-main-menu__subtitle {
              font-size: 2.0em;
            }
          }
          > .bn-main-menu__titles {
            display: flex;
            align-items: center;
            gap: 2em;
            justify-content: space-between;
          }
          .bn-sub-menu-trigger {
            display: none;
          }
        }
        .bn-main-menu__sub-menu {
          > .bn-main-menu__arrow {
            display: none;
          }
          .bn-main-menu__lvl-wrap {
            display: none;
            height: 100vh;
            position: fixed;
            top: 0;
            width: $unit__wdt--header-v3-sub-lvl-wrap-desktop-large;
            z-index: $unit__zi--header-v3-sub-menu-wrap;
            &:before {
              //background-color: $clr__white;
              content: '';
              display: block;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              width: calc(100% - 20px);
            }
            > div {
              height: 100%;
              position: relative;
              margin-left: 20px;
              width: calc(100% - 20px);
              z-index: 5;
              display: flex;
            }
            .bn-main-menu__lvl {
              @include scrollbar($clr__scrollbar,5px);
              list-style-type: none;
              max-height: 100%;
              overflow-y: auto;
              padding: 30px 0 30px 140px;
              height: 100%;
              display: flex;
              align-items: start;
              flex-direction: column;
              align-content: flex-start;
              justify-content: center;
              .bn-main-menu__item {
                .bn-main-menu__link {
                  display: block;
                  font-size: 21px;
                  padding: 10px 40px 15px 0;
                  transition: color .2s ease-out;
                }
              }
            }
            &.bn-main-menu__lvl-wrap--1 {
              left: 50%;
              text-align: left;
              width: 100%;
              height: 100%;
            }
            &.bn-main-menu__lvl-wrap--2 {
              left: calc(#{$unit__wdt--header-v3-sidebar-desktop-large} + #{$unit__wdt--header-v3-sub-lvl-wrap-desktop-large} - 40px);
              > div {
                background-color: rgba($clr__ci--primary, 0.8);
              }
            }
          }
        }
        &:hover {
          > .bn-main-menu__link {
            color: $clr__typo--secondary;
          }
          > .bn-main-menu__sub-menu {
            > .bn-main-menu__lvl-wrap {
              display: block;
              .bn-main-menu__lvl {
                .bn-main-menu__item {
                  &:before {
                    background-color: transparent;
                  }
                  &:hover {
                    > .bn-main-menu__link {

                    }
                    > .bn-main-menu__lvl-wrap {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // top references
  .bn-top-references {
    &.bn-top-references--aside {
      font-size: 2.5em;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: fit-content;
      .bn-top-references__item {
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
        &.bn-top-references__item--trigger {
          display: none;
        }
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      color: $clr__ci--primary;
      ul {
        @include clearfix();
        font-size: 16px;
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
            color: $clr__ci--primary;
            transition: color .2s;
            &:hover {
              color: #a39090;
            }
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__ci--primary;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--aside {
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      gap: 5px;
      letter-spacing: 0.96px;
      padding-left: 5px;
      color: $clr__ci--primary;
      > a {
        color: $clr__ci--primary;
      }
    }
  }
  // call to action
  .bn-call-to-action {
    &.bn-call-to-action--aside {
      margin-top: 60px;
      text-align: left;
      .bn-button {
        @include button--cta();
        max-width: 180px;
        min-width: 0;
        width: 100%;
        background: $clr__ci--tertiary;
        border-color: $clr__ci--tertiary;
        border-radius: 7px;
        font-size: 14px;
        letter-spacing: 2.8px;
        &:hover {
          background: rgba(163, 144, 144, 0.9);
          border-color: $clr__ci--tertiary;
          color: $clr__typo--secondary!important;
        }
      }
    }
  }
  // open
  &.bn-sidebar--open {
    transform: translateY(0px);
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              &.bn-main-menu__lvl-wrap--1 {
                //left: calc(#{$unit__wdt--header-v3-sidebar} - 20px);
              }
              &.bn-main-menu__lvl-wrap--2 {
                left: calc(#{$unit__wdt--header-v3-sidebar} + #{$unit__wdt--header-v3-sub-lvl-wrap} - 40px);
              }
            }
          }
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-sidebar__top {
      padding: 20px 20px 10px 20px;
    }
    .bn-sidebar__center {
      padding-left: 20px;
    }
    .bn-sidebar__bottom {
      padding: 10px 20px 20px 20px;
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--aside {
        margin-top: 20px;
      }
    }
    // main menu
    .bn-main-menu {
      .bn-main-menu__item {
        &:before {
          left: -20px;
          width: calc(100% + 20px);
        }
      }
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 10px 0 10px 0;
            margin: 0 20px 0 0;
          }
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                padding: 10px 0 10px 20px;
                .bn-main-menu__item {
                  .bn-main-menu__link {
                    padding: 10px 20px 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media #{$mq__tablet} {
    .bn-header.bn-header--v3 .bn-sidebar i.bn-icon.bn-icon--close {
      &::before {
        color: $clr__ci--primary;
      }
    }
  }
}
