/*  ==========================================================================
    MOBILE HEADER V3
    ========================================================================== */

@mixin mobile-header--v3() {
  @include mobile-header--v1();
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        color: $clr__white;
        display: flex;
        flex-direction: column;
        li {
          a {
            color: $clr__white;
            &:visited {
              color: $clr__white;
            }
            &:hover {
              color: $clr__gray-light;
            }
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
  // logo
  .bn-main-logo.bn-main-logo--above.gtm-event {
    padding-top: 30px;
  }
  // burger menu
  .bn-burger-menu {
    color: $clr__white;
    padding-top: 30px;
    &:hover {
      color: $clr__ci--primary;
    }
    .bn-burger-menu--icon {
      .bn-burger-menu--line {
        display: block;
        width: 30px;
        height: 2px;
        background: $clr__white;
        margin-bottom: 8px;
        &.bn-burger-menu--middle {
          width: 30px;
          transition: width .4s;
        }
        &:nth-child(3) {
          margin-bottom: 4px;
        }
      }
      .bn-burger-menu--label {
        font-weight: $typo__fw--regular;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.69px;
      }
      &:hover {
        color: $clr__white;
        .bn-burger-menu--line {
          &.bn-burger-menu--middle {
            width: 25px;
          }
        }
      }
    }
  }
  // no vista
  &.bn-mobile-header--no-vista {
    &:not(.bn-mobile-header--minimized) {
      background: none;
      background-color: $clr__ci--primary;
      border-bottom-width: 1px;
      border-color: $clr__white;
      // burger menu
      .bn-burger-menu {
        &:hover {
          color: $clr__ci--secondary;
        }
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    background: $clr__ci--primary;
    border-bottom-width: 1px;
  }
}
