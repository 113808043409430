/*  ==========================================================================
    HEADER V2
    ========================================================================== */

@mixin header--v2() {
  @include header--v1();
  background: linear-gradient(rgba($clr__black,0.7), transparent);
  background-color: transparent;
  border-bottom-width: 0;
  // controls
  .bn-controls {
    color: $clr__white;
    text-shadow: 0 1px 1px rgba($clr__black, 0.7);
    .bn-controls__right {
      .bn-controls__right___top {
        a {
          color: $clr__white;
          &:visited {
            color: $clr__white;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      &:before {
        display: none;
      }
      .bn-main-logo__image {
        &.bn-main-logo__image--big {
          background-color: transparent;
          min-height: $unit__hgt--header-v1;
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    .bn-main-menu__lvl--0 {
      > .bn-main-menu__item {
        > .bn-main-menu__link {
          color: $clr__white;
          &:visited {
            color: $clr__white;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
        .bn-main-menu__sub-menu {
          border-top-color: transparent;
          text-shadow: none;
          > .bn-main-menu__arrow {
            &:before,
            &:after {
              border-top: 1px solid $clr__white;
            }
          }
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__white;
            font-weight: $typo__fw--bold;
          }
        }
      }
    }
  }
  // no vista
  &.bn-header--no-vista {
    background: #fff;
    &:not(.bn-header--minimized) {
      // main logo
      .bn-main-logo {
        &.bn-main-logo--above {
          .bn-main-logo__image {
            &.bn-main-logo__image--big {
              max-height: $unit__hgt--header-v1;
              padding: 0;
              img {
                max-height: calc(#{$unit__hgt--header-v1} - 40px);
              }
            }
          }
        }
      }
      .bn-controls{
        .bn-main-menu{
          .bn-main-menu__link{
            color: $clr__typo--primary;
            text-shadow: none;
            &:hover{
              color: $clr__ci--primary;
            }
          }
        }
        .bn-controls__right {
          .bn-controls__right___top {
            a {
              color: $clr__typo--primary;
              text-shadow: none;
              &:visited {
                color: $clr__typo--primary;
              }
              &:hover {
                color: $clr__ci--primary;
              }
            }
          }
        }
      }
      .bn-tel--above{
        color: $clr__typo--primary;
        text-shadow: none;
      }
      .bn-lang-menu{
        ul {
          li{
            &:before{
              color: #464646;
              text-shadow: none;
            }
          }
        }
      }
    }
  }
  // minimized
  &.bn-header--minimized {
    background: none;
    background-color: $clr__white;
    border-bottom-width: 1px;
    // controls
    .bn-controls {
      color: $clr__typo--primary;
      text-shadow: none;
      .bn-controls__right {
        .bn-controls__right___top {
          a {
            color: $clr__typo--primary;
            &:visited {
              color: $clr__typo--primary;
            }
            &:hover {
              color: $clr__ci--primary;
            }
          }
        }
      }
    }
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            color: $clr__typo--primary;
            &:visited {
              color: $clr__typo--primary;
            }
            &:hover {
              color: $clr__ci--primary;
            }
          }
          .bn-main-menu__sub-menu {
            border-top-color: $clr__white;
            > .bn-main-menu__arrow {
              &:before,
              &:after {
                border-top-color: $clr__ci--primary;
              }
            }
          }
        }
      }
    }
  }
}