/*  ==========================================================================
    OFFERS TEASER
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__offers-teaser--arrows: $clr__ci--primary;
$module-clr__offers-teaser--arrows-hover: $clr__ci--secondary;
$module-clr__offers-teaser--bg-primary: $clr__ci--primary;
$module-clr__offers-teaser--bg-secondary: $clr__ci--secondary;
$module-clr__offers-teaser--bg-tertiary: $clr__ci--tertiary;
$module-clr__offers-teaser--typo-primary: $clr__typo--secondary;
$module-clr__offers-teaser--typo-secondary: $clr__typo--secondary;
$module-clr__offers-teaser--typo-tertiary: $clr__typo--secondary;

/*
 * MODULE
 */

.bn-offers-teaser {
  &__controls {
    display: flex;
    right: -50%;
    bottom: -8%;
    position: relative;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    width: 50%;
    justify-content: space-between;
    @media #{$mq__phablet} {
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
  &__dots {
    .slick-dots {
      width: 120px;
      margin: 10px auto;
      @media #{$mq__tablet} {
        padding-left: 5px;
      }
      li {
        list-style-type: none;
        display: none;
        button {
          border: none;
          background: no-repeat;
          font-size: 16px;
          font-family: $typo__ff--primary;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.84px;
        }
        &.slick-active {
          display: block;
        }
        &:last-child {
          &:before {
            content: "/";
            font-size: 16px;
            font-family: $typo__ff--primary;
            font-weight: 700;
          }
          display: block;
        }
      }
      display: flex;
    }
  }
  > .bn-grid__row {
    > .bn-grid__col {
      position: relative;
    }
  }
  &__headline {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 25px;
    color: $clr__ci--primary;
  }
  .bn-offers-teaser__arrows {
    @include slider--arrows-v2($module-clr__offers-teaser--arrows,$module-clr__offers-teaser--arrows-hover);
    margin-bottom: 0;
    padding: 0 10px 0 0;
    width: fit-content;
    z-index: 5;
    // reflect
    &.bn-offers-teaser__arrows--reflect {
      text-align: left;
    }
    // enlarged
    &.bn-offers-teaser__arrows--enlarged {
      bottom: 0;
      top: auto;
    }
  }
  .bn-offers-teaser__holder {
    .bn-offer-box-v2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        color: $module-clr__offers-teaser--typo-primary;
        &:visited {
          color: $module-clr__offers-teaser--typo-primary;
        }
        &:hover {
          color: $module-clr__offers-teaser--typo-primary;
        }
      }
      .bn-offer-box-v2__left,
      .bn-offer-box-v2__right {
        width: 50%;
      }
      .bn-offer-box-v2__left {
        order: 2;
        padding: 60px 0 0 0;
        position: relative;
        z-index: 5;
        > div {
          position: relative;
        }
        .bn-offer-box-v2__image {
          background-color: $clr__black;
          overflow: hidden;
          img {
            @include img--responsive();
          }
        }
        .bn-offer-box-v2__price {
          background-color: $module-clr__offers-teaser--bg-primary;
          bottom: 20px;
          height: 150px;
          overflow: hidden;
          position: absolute;
          min-width: 150px;
          right: 20px;
          z-index: 5;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-offer-box-v2__amount-addition {
            font-size: 1.6em;
          }
          .bn-offer-box-v2__amount {
            font-size: 2.5em;
            font-weight: $typo__fw--bold;
          }
        }
      }
      .bn-offer-box-v2__right {
        background-color: $module-clr__offers-teaser--bg-primary;
        color: $module-clr__offers-teaser--typo-primary;
        order: 1;
        padding: 60px 100px;
        position: relative;
        display: grid;
        justify-content: start;
        align-items: center;
        .bn-offer-box-v2__title {
          @include typo--subheadline($module-clr__offers-teaser--typo-primary);
        }
        .bn-offer-box-v2__seasondatas,
        .bn-offer-box-v2__nights {
          font-size: calc(#{$typo__fs--text} + 0.4em);
          font-weight: $typo__fw--light;
        }
        .bn-offer-box-v2__seasondatas {
          margin-top: 20px;
        }
        .bn-offer-box-v2__nights {
          margin-top: 10px;
        }
        .bn-offer-box-v2__subtitle {
          font-size: $typo__fs--text;
          margin-top: 40px;
          a {
            text-decoration: underline !important;
          }
        }
        .bn-offer-box-v2__button {
          margin-top: 40px;
          .bn-button {
            text-transform: uppercase;
            font-size: 14px;
            border: 1px solid $clr__white;
            border-radius: 7px;
            padding: 13px 30px;
            line-height: 25px;
            letter-spacing: .83px;
            transition: all .2s;
            &:hover {
              background-color: transparent;
              border-color: $clr__ci--secondary;
              color: $clr__ci--secondary;
            }
          }
        }
      }
    }
    // reflect
    &.bn-offers-teaser__holder--reflect {
      .bn-offer-box-v2 {
        .bn-offer-box-v2__left {
          order: 1;
          .bn-offer-box-v2__price {
            left: 20px;
            right: auto;
          }
        }
        .bn-offer-box-v2__right {
          order: 2;
          &:before {
            left: -25%;
            right: auto;
          }
        }
      }
    }
    // enlarged
    &.bn-offers-teaser__holder--enlarged {
      .bn-offer-box-v2 {
        .bn-offer-box-v2__left {
          padding-top: 0;
        }
        .bn-offer-box-v2__right {
          margin-top: 60px;
        }
      }
    }
    // slider
    &.slick-slider {
      @media screen and (min-width: 1025px) {
        .slick-list {
          height: auto !important;
        }
      }
        .bn-offer-box-v2 {
          display: flex !important;
        }
      }
    }
  }



/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-offers-teaser {
    .bn-offers-teaser__holder {
      .bn-offer-box-v2 {
        .bn-offer-box-v2__left {
          .bn-offer-box-v2__price {
            height: 125px;
            min-width: 125px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-offers-teaser {
    .bn-offers-teaser__arrows {
      // enlarged
      &.bn-offers-teaser__arrows--enlarged {
        bottom: auto;
        top: 0;
      }
    }
    .bn-offers-teaser__holder {
      .bn-offer-box-v2 {
        .bn-offer-box-v2__right {
          .bn-offer-box-v2__subtitle {
            margin-top: 20px;
          }
          .bn-offer-box-v2__button {
            margin-top: 20px;
            .bn-button {
              display: block;
              float: none;
              margin-top: 20px;
              min-width: 0;
              width: 155px;
              height: 38px;
              letter-spacing: 2.4px;
              padding: 7px 23px;
              text-align: center;
            }
          }
        }
      }
      // enlarged
      &.bn-offers-teaser__holder--enlarged {
        .bn-offer-box-v2 {
          .bn-offer-box-v2__left {
            padding-top: 60px;
          }
          .bn-offer-box-v2__right {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-offers-teaser {
    padding: 0 24px;
    .bn-offers-teaser__arrows {
      left: auto;
      position: static;
      top: auto;
      // reflect
      &.bn-offers-teaser__arrows--reflect {
        text-align: right;
      }
      // enlarged
      &.bn-offers-teaser__arrows--enlarged {
        top: auto;
      }
    }
    .bn-offers-teaser__holder {
      .bn-offer-box-v2 {
        display: block;
        .bn-offer-box-v2__left,
        .bn-offer-box-v2__right {
          width: 100%;
        }
        .bn-offer-box-v2__left {
          padding: 0;
          > div {
            display: flex;
            flex-direction: column;
          }
          .bn-offer-box-v2__image {
            @include aspect-ratio(16,9);
            order: 2;
            overflow: hidden;
            position: relative;
            img {
              left: 0;
              position: absolute;
              top: 0;
            }
          }
          .bn-offer-box-v2__price {
            bottom: auto;
            height: auto;
            left: auto !important;
            min-width: 0;
            order: 1;
            position: static;
            right: auto;
            > div {
              display: block;
              height: auto;
            }
            .bn-offer-box-v2__amount-addition,
            .bn-offer-box-v2__amount {
              display: inline-block;
            }
            .bn-offer-box-v2__amount-addition {
              margin: 0 5px;
            }
          }
        }
        .bn-offer-box-v2__right {
          padding: 40px;
          &:before {
            display: none;
          }
        }
      }
      // enlarged
      &.bn-offers-teaser__holder--enlarged {
        .bn-offer-box-v2 {
          .bn-offer-box-v2__left {
            padding-top: 0;
          }
        }
      }
      // slider
      &.slick-slider {
        .slick-list {
          .bn-offer-box-v2 {
            display: block !important;
          }
        }
      }
    }
  }
}
