#bn-quick-request--v3 {

  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  transition: opacity .25s;
  z-index: 500;
  box-shadow: rgba($clr__black, 0.1) 0 2px 6px 4px;
  &.JS-act {
    z-index: 80;
    pointer-events: auto;
    opacity: 1;
  }
  &.JS-menu-open {
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .bn-quickrequest {
    &__dates {
      display: flex;
      align-items: center;
      padding: 15px 0;
      color: $clr__black;
      background: rgba(255, 255, 255, 0.75);
      fill: rgba(255, 255, 255, 0.75);
      filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(5px);
      .bn-todate,
      .bn-fromdate {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        padding: 0 40px 0 40px;
        &__day {
          > span {
            font-size: 31px;
            font-weight: $typo__fw--regular;
          }
        }

        &__month {
          margin-left: 5px;

          > span {
            font-size: 16px;
          }
        }

        &__arrow {
          margin-left: 7px;
        }
      }

      .bn-spacer {
        display: block;
        height: 100%;
        width: 2px;
        background: rgba($clr__black, 0.5);
      }
    }

    &__buttons {
      width: 100%;
      z-index: 501;
      .bn-request-button,
      .bn-book-button {
        text-transform: uppercase;
        font-weight: $typo__fw--bold;
        letter-spacing: 1px;
        padding: 20px 48px;
        color: $clr__white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        transition: opacity .25s;
        width: 100%;
        * {
          font-size: 17px;
        }
        &:hover {
          opacity: 0.8;
        }
      }

      .bn-request-button {
        background-color: #A07A7B;
      }

      .bn-book-button {
        background-color: #ffffff;
        color: #473D3E;
      }
    }
  }
  @media #{$mq__desktop--medium} {
    .bn-quickrequest {
      &__buttons {
        .bn-request-button,
        .bn-book-button {
          * {
            font-size: 14px;
          }
        }
      }
      &__dates {
        .bn-spacer {
        }
      }
    }
  }
  @media #{$mq__tablet} {
    display: none!important;
  }
}