/*  ==========================================================================
    FOOTER V2
    ========================================================================== */

@mixin footer--v2() {
  @include footer--v1();

  background: linear-gradient(180deg, rgba(231, 230, 230, 0.80) 0%, #E7E6E6 100%);

  .bn-footer {
    &-wrap {
      padding-bottom: 140px;
    }
    &-bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .05;
      }
    }
  }
  .bn-footerpartner {
    display: flex;
    flex-direction: column;
    gap: 7em;
    margin-top: 15px;
    @media #{$mq__phablet} {
      gap: 3em 7em;
    }
  }
  .bn-triggers {
    display: flex;
    align-items: center;
    gap: 7em;
    > div {
      font-size: 1.8em;
      color: $clr__ci--primary;
      cursor: pointer;
      list-style: none;
      display: flex;
      align-items: center;
      gap: .7em;
      &.JS-active {
        svg {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }
  }
  // footer center
  .bn-f-center {
    padding: 40px 0;
  }
  // weather teaser
  .bn-weather-teaser {
    &.bn-weather-teaser--large {
      width: 75%;
      padding: 0 0 0 15px;
      .bn-weather-teaser__title {
        font-size: 2em;
        text-transform: uppercase;
        font-weight: $typo__fw--bold;
        margin-bottom: 1em;
      }
      .bn-weather-teaser__forecast {
        .bn-loader {
          color: $clr__typo--secondary;
        }
        .bn-weather-table {
          text-align: center;
          width: 100%;
          .bn-weather-table__icons {
            font-size: 5.0em;
          }
          .bn-weather-table__dates,
          .bn-weather-table__temps {
            font-size: 1.6em;
          }
        }
      }
    }
  }
  // newsletter
  .bn-newsletter-v2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: $clr__ci--primary;
    padding: 90px 200px 10px 200px;
    &__left {
      font-size: 3.7em;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      text-transform: uppercase;
    }
    &__center {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 33%;
      span:nth-of-type(1) {
        font-size: 1.7em;
        font-weight: 400;
        line-height: normal;
        letter-spacing: .34px;
        text-transform: uppercase;
      }
      span:nth-of-type(2) {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.26px;
        width: 100%;
        border-bottom: 1px solid $clr__ci--primary;
      }
    }
    &__right {
      text-align: center;
      padding: 10px 45px;
      border: 1px solid $clr__ci--primary;
      border-radius: 7px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 2.52px;
      text-transform: uppercase;
      transition: opacity .2s;
      cursor: pointer;
      &:hover {
        opacity: .7;
      }
    }
  }
  /*
   * RESPONSIVE BEHAVIOR
   */

  // laptop
  @media #{$mq__laptop} {
    .bn-newsletter-v2 {
      gap: 40px;
      padding: 80px 100px 10px;
      &__left {
        font-size: 3em;
      }
      &__center {
        span:nth-of-type(1) {
        }
        span:nth-of-type(2) {
        }
      }
      &__right {
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    // top
    .bn-grid__col.bn-grid__col--flex-none.bn-f-top__box.bn-f-top__box--1 {
      display: none;
    }
    .bn-f-top {
      .bn-f-top__boxes {
        flex-direction: column;
      }
      &__box--3 {
        display: block;
      }
    }
    .bn-newsletter-v2 {
      gap: 20px;
      padding: 80px 40px 10px;
      &__left {
        font-size: 2.5em;
        line-height: 20px;
      }
      &__right {
        padding: 10px 30px;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    // newsletter
    .bn-newsletter-v2 {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 10px;
      margin: 0 50px;
      align-items: flex-start;
      &__left {
      }
      &__center {
        width: 100%;
        span:nth-of-type(1) {
          font-size: 1.7em;
          font-weight: 400;
          line-height: normal;
          letter-spacing: .34px;
        }
        span:nth-of-type(2) {
          width: 100%;
          border-bottom: 1px solid $clr__ci--primary;
        }
      }
      &__right {
        width: 100%;
        padding: 10px 45px;
      }
    }

    .bn-weather-teaser {
      &.bn-weather-teaser--large {
        padding-left: 20px;
        padding-right: 20px;
        .bn-weather-teaser__title {
          display: none;
        }
        .bn-weather-teaser__forecast {
          width: 100%;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-weather-teaser {
      &.bn-weather-teaser--large {
        .bn-weather-teaser__forecast {
          .bn-weather-table {
            .bn-weather-table__icons {
              font-size: 3.5em;
            }
            .bn-weather-table__dates,
            .bn-weather-table__temps {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}