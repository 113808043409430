/*  ==========================================================================
    CONTENTCLUSTER LIST VIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__cc-list-view--accent: $clr__cta--primary;
$module-clr__cc-list-view--arrows: $clr__ci--primary;
$module-clr__cc-list-view--arrows-hover: $clr__ci--secondary;
$module-clr__cc-list-view--bg-primary: $clr__ci--primary;
$module-clr__cc-list-view--bg-secondary: $clr__ci--secondary;
$module-clr__cc-list-view--bg-tertiary: $clr__ci--tertiary;
$module-clr__cc-list-view--typo-primary: $clr__typo--secondary;
$module-clr__cc-list-view--typo-secondary: $clr__typo--secondary;
$module-clr__cc-list-view--typo-tertiary: $clr__typo--primary;

/*
 * MODULE
 */

.bn-cc-list-view {
  .bn-cc-list-view__holder {
    .bn-cc-list-view__head {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .bn-cc-list-view__headline {
        font-size: 1.6em;
        font-weight: $typo__fw--regular;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .bn-cc-list-view__arrows {
        @include slider--arrows-v2($module-clr__cc-list-view--arrows,$module-clr__cc-list-view--arrows-hover);
        margin-bottom: 10px;
        margin-left: auto;
        padding-left: 20px;
        white-space: nowrap;
      }
    }
    .bn-cc-list-view__items {
      &.bn-cc-list-view__type-list,
      &.bn-cc-list-view__type-slider {
        @include cc--box($module-clr__cc-list-view--accent,$module-clr__cc-list-view--bg-primary,$module-clr__cc-list-view--bg-secondary,$module-clr__cc-list-view--bg-tertiary,$module-clr__cc-list-view--typo-primary,$module-clr__cc-list-view--typo-secondary,$module-clr__cc-list-view--typo-tertiary);
      }
      &.bn-cc-list-view__type-slider {
        @include clearfix();
      }
      &.bn-cc-list-view__type-list {
        .bn-cc-list-view__pagination,
        .bn-loader {
          margin-top: 40px;
        }
        .bn-cc-list-view__pagination {
          text-align: center;
          .bn-button {
            @include button--default();
          }
        }
      }
    }
  }
  // show dates only if wished
  &.bn-cc-list-view--dated {
    .bn-cc-list-view__holder {
      .bn-cc-list-view__items {
        .bn-cc-box {
          .bn-cc-box__content {
            .bn-cc-box__headlines {
              .bn-cc-box__date {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  // hide image if smaller output is set
  &.bn-cc-list-view--size-s {
    .bn-cc-list-view__holder {
      .bn-cc-list-view__items {
        .bn-cc-box {
          .bn-cc-box__image {
            display: none !important;
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phone
@media #{$mq__phone} {
  .bn-cc-list-view {
    .bn-cc-list-view__holder {
      .bn-cc-list-view__head {
        .bn-cc-list-view__headline {
          margin-bottom: 10px;
        }
        .bn-cc-list-view__arrows {
          margin-bottom: 5px;
          padding-left: 10px;
        }
      }
      .bn-cc-list-view__items {
        &.bn-cc-list-view__type-list {
          .bn-cc-list-view__pagination,
          .bn-loader {
            margin-top: 20px;
          }
        }
      }
    }
  }
}