/*  ==========================================================================
    FOOTER V1
    ========================================================================== */

@mixin footer--v1() {
  padding-bottom: calc(#{$unit__hgt--footer-fixed-bar} + 60px);
  // footer top
  .bn-f-top {
    padding: 40px 0 0 0;
    .bn-f-top__boxes {
      padding: 90px 0 85px;
      margin: 0 200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $clr__ci--primary;
      border-top: 1px solid $clr__ci--primary;
      .bn-f-top__box {
        color: $clr__ci--primary;
        a {
          color: $clr__ci--primary;
          &:visited {
            color: $clr__ci--primary;
          }
          &:hover {
            color: rgba(103, 89, 90, 0.6);
          }
        }
        &.bn-f-top__box--1 {
          width: fit-content;
        }
        &.bn-f-top__box--2 {
          max-width: calc(25% - 20px);
        }
        &.bn-f-top__box--3 {
          max-width: calc(35% - 30px);
        }
        &.bn-f-top__box--4 {
          max-width: calc(20% - 30px);
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          @media #{$mq__phablet} {
            max-width: none;
            width: 100%;
            align-items: start;
            justify-content: start;
            padding: 0 20px 0 40px;
          }
          .bn-references {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @media #{$mq__phablet} {
              display: flex;
              justify-content: center;
              align-items: start;
              flex-direction: row;
              gap: 6em;
            }
          }
        }
      }
      // accordion
      .bn-accordion {
        .bn-accordion__trigger {
          display: none;
        }
      }
    }
    .bn-f-top__credits {
      .bn-f-top__credits__right {
        padding-top: 60px;
      }
    }
  }
  // footer bottom
  .bn-f-bottom {
    .bn-f-bottom__partners {
    }
    .bn-f-bottom__useful-sites {
    }
  }
  // weather teaser
  .bn-weather-teaser {
    &.bn-weather-teaser--small {
      color: $clr__ci--primary;
      display: flex;
      flex-wrap: wrap;
      font-size: 2em;
      font-weight: $typo__fw--bold;;
      .bn-weather-teaser__title,
      .bn-weather-teaser__forecast {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .bn-weather-teaser__forecast {
        margin-left: 10px;
        .bn-loader {
          font-size: 1.0em;
        }
        .weather-icon {
          font-size: 1.3em;
          padding: 0 5px;
        }
      }
    }
  }
  // main logo
  .bn-main-logo--footer {
    width: auto;
    height: 190px;
  }
  // address
  .bn-address {
    &.bn-address--v1 {
      font-size: 2em;
      font-style: normal;
      line-height: 25px;
      padding: 0 10px 0 40px;
      width: 100%;
      .bn-address__item {
        &.bn-address__company {
          font-weight: $typo__fw--bold;
          height: 25px;
          display: block;
          text-transform: uppercase;
        }
        &.bn-address__socials {
          display: flex;
          gap: 30px;
          margin-top: 30px;
          align-items: center;
        }
        &.bn-address__item--de,
        &.bn-address__item--en,
        &.bn-address__item--it {
          display: none;
        }
      }
      &.bn-address--de {
        .bn-address__item {
          &.bn-address__item--de {
            display: inline;
          }
        }
      }
      &.bn-address--en {
        .bn-address__item {
          &.bn-address__item--en {
            display: inline;
          }
        }
      }
      &.bn-address--it {
        .bn-address__item {
          &.bn-address__item--it {
            display: inline;
          }
        }
      }
    }
  }
  // map
  .bn-map {
    height: 100%;
    position: relative;
    width: 100%;
    .bn-map__canvas {
      align-items: center;
      background-color: $clr__black;
      display: flex;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .bn-button {
      &.bn-button--getting-there {
        @include button--custom($clr__ci--primary,$clr__ci--primary,$clr__typo--secondary,$clr__white,$clr__white,$clr__ci--primary);
        bottom: 20px;
        max-width: 250px;
        min-width: 0;
        position: absolute;
        right: 20px;
        width: calc(100% - 40px);
        z-index: 5;
      }
    }
  }
  // newsletter
  .bn-form__row {
    background-color: $clr__white;
  }
  #bn_newsletter_v2 .bn-form__label {
    color: $clr__ci--primary !important;
    border-bottom-color: $clr__ci--primary !important;
  }
  .bn-newsletter {
    &.bn-newsletter--small {
      padding: 20px 40px;
      width: 100%;
      .bn-newsletter__title {
        font-size: 1.6em;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
    }
  }
  #bn_newsletter_v2 .bn-form__submit .bn-form__button {
    background-color: transparent !important;
    color: $clr__ci--primary !important;
    border-color: $clr__ci--primary !important;
  }
  #bn_newsletter_v2 .bn-form__submit .bn-form__button:hover {
    background-color: $clr__white !important;
    color: $clr__ci--primary !important;
    border-color: $clr__ci--primary !important;
  }
  #bn_newsletter_v2 .bn-form-newsletter .bn-form__group input,
  #bn_newsletter_v2 .bn-form-newsletter .bn-form__group select,
  #bn_newsletter_v2 .bn-form__group textarea {
    border-bottom: 1px solid $clr__ci--primary !important;
  }
  // references
  .bn-references {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3em;
    a {
      color: $clr__ci--primary;
      &:visited {
        color: $clr__ci--primary;
      }
      &:hover {
        color: $clr__ci--secondary;
      }
    }
    .bn-references__item {
      display: inline-block;
      .bn-icon {
        font-size: 3.5em;
        &.bn-icon--facebook1,
        &.bn-icon--insta,
        &.bn-icon--youtube1 {
          font-size: 4.0em;
        }
        &.bn-icon--Holidaycheck,
        &.bn-icon--tripadvisor {
          font-size: 3.3em;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // legal menu
  .bn-legal-menu {
    &.bn-legal-menu--v1 {
      font-size: 1.8em;
      line-height: 29px;
      letter-spacing: .36px;
      text-align: right;
      display: flex;
      justify-content: space-between;
      a {
        color: $clr__ci--primary;
        &:visited {
          color: $clr__typo--primary;
        }
        &:hover {
          color: $clr__ci--primary;
        }
      }
      .bn-break {
        display: none;
      }
      .bn-legal-menu__item {
        &.bn-legal-menu__item--cur {
          color: $clr__ci--primary;
        }
      }
      .bn-powered-by {
        color: $clr__black;
        display: inline-block;
        margin-top: 10px;
        width: 90px;
        img {
          width: 100%;
          height: auto;
        }
        &:visited {
          color: $clr__black;
        }
        &:hover {
          color: $clr__bn-red;
        }
      }
    }
  }
  // Footer Links
  .footer-links {
    display: flex;
    gap: 55px;
  }
  .bn-partners {
    text-align: left;
    font-size: 1.8em;
    color: $clr__ci--primary;
    display: none;
    flex-wrap: wrap;
    gap: 2em 4em;
    z-index: 10;
    &.JS-active {
      display: flex;
    }
    summary {
      list-style: none;
    }
    .bn-partners__item {
      display: inline-block;
      img {
        @include img--rendering-crisp-edges();
        height: 80px;
        width: auto;
      }
    }
  }
  .bn-useful-sites {
    text-align: left;
    font-size: 1.8em;
    color: $clr__ci--primary;
    display: none;
    &.JS-active {
      display: block;
    }
    summary {
      list-style: none;
    }
    a {
      color: $clr__typo--primary;
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-useful-sites__title {
      display: inline-block;
    }
  }
  // view switcher
  .bn-view-switcher {
    display: none !important; //remove !important if you want enable version switcher
    font-size: 1.4em;
    font-style: italic;
    padding-top: 60px;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $clr__typo--primary;
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-view-switcher__trigger {
      &.bn-view-switcher__trigger--web {
        display: none;
      }
    }
    &.bn-view-switcher--view-switched {
      display: block;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 70px;
        }
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 60px;
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-f-top {
      padding: 40px 0 0 0;

      .bn-f-top__boxes {
        padding: 70px 0 65px;
        margin: 0 100px;
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    // top
    .bn-f-top {
      .bn-f-top__boxes {
        padding-top: 10px;
        .bn-f-top__box {
          flex: none;
          &.bn-f-top__box--1,
          &.bn-f-top__box--2 {
            max-width: 100%;
            width: calc(50% - 10px);
          }
          &.bn-f-top__box--3 {
            margin-top: 20px;
            max-width: 100%;
            width: 100%;
          }
        }
      }
      .bn-f-top__credits {
        flex-direction: column;
      }
    }
    // references
    .bn-references {
      text-align: center;
      .bn-references__item {
        margin-right: 0;
        padding: 0 20px;
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        text-align: center;
        display: flex;
        flex-direction: column;
        .bn-legal-menu__powered {
          position: absolute;
          bottom: 3em;
          .bn-powered-by {
            margin-top: 20px;
          }
        }
      }
    }
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 60px;
        }
      }
    }
    // useful pages
    .bn-useful-sites {
      .bn-useful-sites__title {
        display: block;
        margin-right: 0;
      }
    }
    // view switcher
    .bn-view-switcher {
      display: block;
      .bn-view-switcher__trigger {
        &.bn-view-switcher__trigger--mobile {
          display: none;
        }
        &.bn-view-switcher__trigger--web {
          display: inline;
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    // top
    .bn-f-top {
      .bn-f-top__boxes {
        display: block;
        margin: 0 50px;
        .bn-f-top__box {
          &.bn-f-top__box--1,
          &.bn-f-top__box--2 {
            width: 100%;
          }
          &.bn-f-top__box--1 {
            min-height: 0;
          }
          &.bn-f-top__box--2 {
            margin-top: 20px;
          }
        }
        // accordion
        .bn-accordion {
          display: block;
          min-height: 0;
          .bn-accordion__trigger {
            cursor: pointer;
            display: block;
            line-height: 20px;
            padding: 15px 60px 15px 20px;
            position: relative;
            text-transform: uppercase;
            .bn-accordion__title {
              font-size: 1.6em;
            }
            .bn-accordion__state {
              font-size: 1.8em;
              height: 20px;
              line-height: 20px;
              margin-top: -10px;
              position: absolute;
              right: 20px;
              top: 50%;
              text-align: center;
              width: 20px;
              z-index: 5;
              .bn-icon {
                &.bn-icon--arrow-top {
                  display: none;
                }
              }
            }
            &:hover {
              background-color: $clr__ci--secondary;
            }
            &.bn-accordion__trigger--act {
              .bn-accordion__state {
                font-size: 1.8em;
                height: 20px;
                line-height: 20px;
                margin-top: -10px;
                position: absolute;
                right: 20px;
                top: 50%;
                text-align: center;
                width: 20px;
                z-index: 5;
                .bn-icon {
                  &.bn-icon--arrow-bottom {
                    display: none;
                  }
                  &.bn-icon--arrow-top {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .bn-accordion__slide {
            align-items: center;
            display: none;
            min-height: 250px;
            overflow: hidden;
            &.bn-accordion__slide--open {
              display: flex;
            }
          }
        }
      }
    }
    .bn-references {
      .bn-references__item {
        padding: 0 10px;
      }
    }
    // weather teaser
    .bn-weather-teaser {
      &.bn-weather-teaser--small {
        display: block;
        .bn-weather-teaser__title {
          display: block;
        }
        .bn-weather-teaser__forecast {
          margin-left: 0;
        }
      }
    }
    // newsletter
    .bn-newsletter {
      &.bn-newsletter--small {
        .bn-newsletter__title {
          display: none;
        }
        .bn-newsletter__form {
          width: 100%;
        }
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        .bn-legal-menu__credits {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
        }
        .bn-pipe {
          display: none;
        }
        .bn-break {
          display: inline;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-f-top {
      .bn-f-top__boxes {
        display: block;
        margin: 0;
      }
    }
    // weather teaser
    .bn-weather-teaser {
      &.bn-weather-teaser--small {
        font-size: 1.4em;
      }
    }
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 45px;
        }
      }
    }
  }
}
