/*  ==========================================================================
    MIXINS
    Collection of all mixins (mind the ordering!).

    INFO:
    - try to use mixins as much as possible, it makes life easier
    - "_general.scss" always first!
    - each header and footer type has is own mixin
    ========================================================================== */

// general
@import 'lib/general';
@import 'lib/buttons';
@import 'lib/contentcluster';
@import 'lib/image';
@import 'lib/offers';
@import 'lib/price-lists';
@import 'lib/progress-bar';
@import 'lib/quick-request-horizontal';
@import 'lib/quick-request-vertical';
@import 'lib/rooms';
@import 'lib/slider';
@import 'lib/typography';

// headers
@import 'lib/headers/v1';
@import 'lib/headers/v2';
@import 'lib/headers/v3';
@import 'lib/headers/v4';

// headers lib
@import 'lib/headers/lib/sidebar';

// mobile headers
@import 'lib/mobile-headers/v1';
@import 'lib/mobile-headers/v2';
@import 'lib/mobile-headers/v3';
@import 'lib/mobile-headers/v4';

// mobile headers lib
@import 'lib/mobile-headers/lib/control-bar';

// footers
@import 'lib/footers/v1';
@import 'lib/footers/v2';
@import 'lib/footers/v3';

// quick request
@import 'lib/quick-request/v1';
@import 'lib/quick-request/v2';

// request
@import 'lib/request/v1';