/*  ==========================================================================
    QUICKLINKS
    ========================================================================== */

/* COLORS */

/* MODULE */

.bn-quicklinks {
  z-index: 1;
  position: relative;
  &:after {
    content: "";
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: -1;
    pointer-events: none;
  }
  &__list {
    display: flex;
    gap: 20px;
    justify-content: center;
    list-style: none;
    padding: 0;
    &--item {

    }
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: $clr__white;
    font-size: 2.5em;
    line-height: 2.5em;
    letter-spacing: 3.25px;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    padding-top: 33%;
    background: rgba(103, 89, 90, 0.5);
    z-index: 2;
    pointer-events: none;
  }
  &__link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      scale: 1;
      transition: transform .5s cubic-bezier(0, 0, 0.5, 1.25);
      z-index: 1;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

/* Responsive Design */
@media #{$mq__desktop--small} {
  .bn-quicklinks {
    &__list {
      &--item {
      }
    }
    &__title {
    }
    &__link {
      picture {
        display: block;
        width: 400px;
        height: 200px;
      }
    }
  }
}

@media #{$mq__laptop} {
  .bn-quicklinks {
    &__list {
      &--item {
      }
    }
    &__title {
    }
    &__link {
      picture {
        display: block;
        width: 300px;
        height: 170px;
      }
    }
  }
}
@media #{$mq__tablet} {
  .bn-quicklinks {
    &__list {
      &--item {
      }
    }
    &__title {
      font-size: 1.75em;
      line-height: 1.7em;
    }
    &__link {
      picture {
        display: block;
        width: 230px;
        height: 160px;
      }
    }
  }
}
@media #{$mq__phablet} {
  .bn-quicklinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 75px 30px 10px;
    &:after {
      height: 35%;
    }
    &__list {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      &--item {
      }
    }
    &__title {
      padding-top: 1.3em;
    }
    &__link {
      picture {
        display: block;
        width: 326px;
        height: 70px;
      }
    }
  }
}

@media (max-width: 350px) {
  .bn-quicklinks {
    &__title {
      padding-top: 1.5em;
      font-size: 1.6em;
      line-height: 2.1em;
    }
    &__link {
      picture {
        display: block;
        width: 280px;
        height: 70px;
      }
    }
  }
}