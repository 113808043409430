/*  ==========================================================================
    HEADER V3
    ========================================================================== */

@mixin header--v3() {
  left: 0;
  min-height: $unit__hgt--header-v3;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  // bg layer
  .bn-sidebar__layer {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background: $clr__ci--primary;
    opacity: .85;
    transform: translateY(-100vh);
    transition: transform .35s;
  }
  .bn-sidebar__layer--active {
    transform: translateY(0);
  }
  // controls
  .bn-controls {
    color: $clr__white;
    .bn-controls__left,
    .bn-controls__center,
    .bn-controls__right {
      padding: 50px 0 10px 0;
      text-align: right;
      opacity: 1;
      transition: all .2s ease;
      .bn-burger-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @media (max-width: 1025px) {
          position: fixed;
          right: 10px;
        }
        &__right {
          background: transparent;
          width: 85px;
          height: 85px;
          transition: background .2s ease;
          .bn-burger-menu {
            display: flex;
            justify-content: center;
            margin-top: 15.81px;
          }
        }
        &__left {
          background: transparent;
          width: 85px;
          height: 85px;
          opacity: 0;
          left: 85px;
          position: relative;
          pointer-events: none;
          z-index: -1;
          transition: background .4s ease, opacity .4s ease, left .4s;
          .bn-main-logo {
            display: flex;
            justify-content: center;
            padding: 0;
            margin-top: 12px !important;
          }
        }
      }
    }
    .bn-controls__center {
      text-align: center;
      max-width: 280px;
    }
    .bn-controls__left {
      display: flex;
      gap: 50px;
      text-align: left;
      a {
        color: $clr__white;
        transition: color .2s;
        &:visited {
          color: $clr__white;
        }
        &:hover {
          color: $clr__gray-light;
        }
      }
    }
  }
  // burger menu
  .bn-burger-menu {
    cursor: pointer;
    display: inline-block;
    pointer-events: initial;
    .bn-icon {
      font-size: 5.0em;
    }
    &.bn-sidebar-trigger--act {
      display: none;
    }
    .bn-burger-menu--icon {
      .bn-burger-menu--line {
        display: block;
        width: 40px;
        height: 2px;
        background: $clr__white;
        margin-bottom: 12px;
        &.bn-burger-menu--middle {
          width: 40px;
          transition: width .4s;
        }
        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
      .bn-burger-menu--label {
        font-weight: $typo__fw--regular;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1.69px;
      }
      &:hover {
        color: $clr__white;
        .bn-burger-menu--line {
          &.bn-burger-menu--middle {
            width: 30px;
          }
        }
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--aside {
      margin-top: 40px;
      img {
        @include img--rendering-crisp-edges();
        max-width: 100%;
      }
      .bn-main-logo__image {
        &.bn-main-logo__image--big {
          display: none;
        }
        &.bn-main-logo__image--big--novista {
          display: none;
        }
        &.bn-main-logo__image--small {
          display: inline-block;
          img {
            height: 60px;
          }
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // sidebar
  .bn-sidebar {
    @include header--sidebar();
    pointer-events: initial;
    background-size: 110% auto;
    background-position: center;
    background-repeat: no-repeat;
    &:before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: hsla(356, 7%, 38%, .85);
    }
    &:after {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #E7E6E6;
      opacity: .92;
    }
    &__top,
    &__bottom {
      z-index: 1;
      position: relative;
    }
    .bn-main-menu__img {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      pointer-events: none;
      z-index: -1;
      overflow: hidden;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: auto;
        opacity: .2;
      }
    }
    &__head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--right {
        display: flex;
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: inline-block;
      pointer-events: initial;
      .bn-main-logo__image {
        img {
          @include img--rendering-crisp-edges();
          max-width: 240px;
          width: 171px;
          height: 197px;
        }
        &.bn-main-logo__image--big--novista {
          display: none;
        }
        &.bn-main-logo__image--small {
          display: none;
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
    &.bn-main-logo--aside {
      margin-top: 0 !important;
      padding-right: 50px;
    }
  }

  //add arrow to menu with submenu
  .bn-main-menu__item--has-children .bn-main-menu__titles::after{
    content: "\e902";
    font-family: 'bn-icons' !important;
    font-size: 2.6em;
    margin-left: 5px;
    transition: transform .3s ease-out;
  }

  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      font-size: 20px;
      line-height: 30px;
      ul {
        @include clearfix();
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__typo--secondary;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  // tel
  .bn-tel {
    &.bn-tel--above {
      font-size: 2em;
      display: inline-block;
      height: $unit__hgt--header-v3_tel;
      line-height: $unit__hgt--header-v3_tel;
      pointer-events: initial;
      white-space: nowrap;
      .break {
        display: none;
      }
    }
  }
  // header scroll
  &.bn-header--minimized {
    .bn-controls {
      &__left {
        opacity: 0;
        position: relative;
        z-index: -1;
      }
      &__center {
        opacity: 0;
        pointer-events: none;
      }
      &__right {
        .bn-burger-button {
          &__left {
            box-shadow: 0 0 10px #00000080;
            left: 0;
            position: relative;
            background: $clr__ci--primary;
            opacity: 1;
            pointer-events: all;
          }
          &__right {
            box-shadow: 3px 0 7px #00000080;
            background: #B9AAAB;
          }
        }
      }
    }
  }

  /* RESPONSIVE BEHAVIOR */
  // full screen on
  @media #{$mq__full-screen--on} {
    position: fixed;
  }
  // laptop
  @media #{$mq__laptop} {
    // controls
    .bn-controls {
      .bn-controls__center {
        max-width: 200px;
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            width: fit-content;
          }
        }
      }
    }
  }
  @media #{$mq__tablet} {

    a.bn-main-menu__link.bn-main-menu__link--lvl-0.bn-main-menu__prevent-link-on-touch,
    a.bn-main-menu__link.bn-main-menu__link--lvl-0 {
      border-bottom: 1px solid $clr__ci--primary;
    }
    .bn-sidebar {
      height: 100%!important;
      &__center {
        height: calc(100vh - 23em);
        > div {
          height: 100%!important;
        }
        &.bn-main-menu__items {
          width: 100%!important;
        }
      }
      &:before {
        display: none;
      }
      &:after {
        width: 100%;
        background: rgba(231, 230, 230, 0.95);
      }
      .bn-tel.bn-tel--aside,
      .bn-close,
       a {
        color: $clr__ci--primary!important;
      }
      .bn-close {
        .bn-icon.bn-icon--close {
          color: #3f3f3f;
        }
      }
      .bn-main-menu {
        &.bn-main-menu--v1.bn-main-menu--aside {
          overflow: auto;
          height: 100%;
          max-height: calc(100% - 20em);
        }
        .bn-main-menu__lvl--0 {
          .bn-main-menu__item {
            width: 100%;
            > a {
              display: flex!important;
              justify-content: space-between;
            }
            .bn-sub-menu-trigger {
              display: flex!important;
              align-items: center;
              font-size: 20px;
              position: absolute;
              padding: 3px;
              right: 20px;
              z-index: 10;
              color: $clr__ci--primary!important;
              top: 15px;
              &.JS-active {
                display: inline-block;
                transform: rotate(180deg);
              }
            }
            &:hover>.bn-main-menu__sub-menu>.bn-main-menu__lvl-wrap {
              display: none;
            }
            > .bn-main-menu__link {
              >.bn-main-menu__titles {
                width: 100%;
                .bn-main-menu__title {
                  width: 75%;
                  font-size: 19px;
                }
              }
            }
            &--has-children {
              .bn-main-menu__titles:after {
                display: none;
              }
            }
          }
        }
        &__sub-menu {
          .bn-main-menu__lvl-wrap--1 {
            position: relative!important;
            left: 0!important;
            > div {
              margin-left: 0!important;
              ul {
                padding: 0!important;
                a {
                  width: 100%;
                }
              }
            }
            &:before {
              display: none!important;
            }
          }
        }
      }
      .bn-lang-menu.bn-lang-menu--aside ul {
        align-items: flex-start;
        flex-direction: row;
        color: $clr__ci--primary!important;
      }
      .bn-top-references.bn-top-references--aside {
        flex-direction: row;
      }
    }

    .bn-main-logo.bn-main-logo--aside {
      display: none;
    }

    .bn-lang-menu.bn-lang-menu--aside {
      ul {
        display: flex;
        flex-direction: column;
        li {
          &::before {
            content: "";
          }
        }
      }
    }
  }
  @media  #{$mq__phablet} {
    /* Controls */
    .bn-controls {
      .bn-controls__left,
      .bn-controls__center,
      .bn-controls__right {
        padding: 50px 0 10px 0;
        .bn-burger-button {
          &__right {
            width: 60px;
            height: 60px;
            .bn-burger-menu {
              margin-top: 11.41px;
            }
          }
          &__left {
            width: 60px;
            height: 60px;
            .bn-main-logo {
              margin-top: 9px !important;
            }
          }
        }
      }
      .bn-controls__center {
        max-width: 280px;
      }
      .bn-controls__left {
        gap: 50px;
        font-size: 2.0em;
      }
    }
    .bn-main-logo.bn-main-logo--aside .bn-main-logo__image.bn-main-logo__image--small img.lazyloaded {
      height: 40px;
    }
    .bn-grid.bn-sidebar.bn-sidebar--open {
      width: 100%;
    }
    /* Burger Menu */
    .bn-burger-menu {
      .bn-icon {
      }
      .bn-burger-menu--icon {
        .bn-burger-menu--line {
          width: 30px;
          margin-bottom: 9px;
          &.bn-burger-menu--middle {
            width: 30px;
          }
          &:nth-child(3) {
            margin-bottom: 5px;
          }
        }
        .bn-burger-menu--label {
          font-size: 9px;
        }
      }
    }

    .bn-tel.bn-tel--above {
      display: none;
    }
  }
}